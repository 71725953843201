@import '../../../../styles/vars';
@import '../../../../styles/mixins';

body {
  margin: 0;
}

.updt-email-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 280px;

  .label-field {
    font: normal 500 14px/1.43 $rionasans-medium;
    margin-bottom: 7px;
    margin-top: 8px;
  }

  .required::before {
    content: '*';
    color: $required-red-color;
    font-size: 14px;
    margin-left: -5px;
  }

  .input-section {
    input {
      font: normal 16px/1.71 $rionasans-regular;
    }

    .gm-form-control.email {
      padding: 0 1px;
    }
  }

  .submit-button {
    button {
      width: 100%;
    }
  }

  .form-fields {
    margin-top: 65.5px;

    form {
      max-width: 400px;
      margin: 0 auto;

      .submit-button {
        button {
          width: 100%;
        }
      }
    }
  }
}