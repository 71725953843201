@import "../../../../styles/vars";

.updt-recrd-page {
  min-height: 100vh;
  color: $text-color-V2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: $page-background-color-V2 url("../../../../images/bg-patients.svg") center repeat-x fixed;
  -webkit-font-smoothing: antialiased;

  .form-wrapper {
    width: 90%;
    max-width: 384px;
    min-height: 549px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    padding: 32px;
    margin: 0 auto;
    z-index: 1;
    box-sizing: border-box;
    // position: relative;

    .genome-logo {
      display: inline-block;
      background-image: url("../../../../images/logo.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 63px;
      background-position: center;
    }

    .updt-recrd-title {
      font: normal 500 24px/1.42 $rionasans-medium;
      margin: 24px 0 4px;
      text-align: center;
      width: 100%;
      display: inline-block;
      -webkit-font-smoothing: antialiased;
    }

    .updt-recrd-desc {
      font: normal 14px/1.43 $rionasans-regular;
      text-align: center;
      margin-bottom: 32px;
      margin-left: -1px;
    }
  }

  /* End: form-wrapper */
}